import axios from "axios";

export class ApiService {
     refreshCalls = 0
     defaultHeaders = {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "Content-Type": "application/json"
     }

     refreshAccessToken = (callback = () => {}, errorHandler = () => {}, options = {}) => {
        axios.post(process.env.REACT_APP_AUTH_API_URL + "/v1/auth/token/refresh", {}, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("refresh"),
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                localStorage.setItem("access_token", response.data["access_token"])
                this.defaultHeaders = {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                    "Content-Type": "application/json"
                 }
                if(this.refreshCalls < 5) {
                    callback({headers: this.defaultHeaders, ...options})
                } else {
                    this.refreshCalls = 0
                }
            })
            .catch((error) => {
                errorHandler(error);
            })      
    }

    get = (url, config, responseHandler, errorHandler) => {
        axios.get(process.env.REACT_APP_API_URL + url, {  ...config, headers: { ...this.defaultHeaders, ...config?.headers} })
            .then((response) => {
                    this.refreshCalls = 0
                    responseHandler(response)
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    this.refreshCalls++
                    this.refreshAccessToken((headers) => this.get(url, headers, responseHandler, errorHandler), errorHandler, config)
                } else {
                    errorHandler(error)
                }
            })
    } 

    post = (url, config, responseHandler, errorHandler, data = {}) => {
        axios.post(process.env.REACT_APP_API_URL + url, data,{ headers: {  ...this.defaultHeaders, ...config }})
            .then((response) => {
                    this.refreshCalls = 0
                    responseHandler(response)
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    this.refreshCalls++
                    this.refreshAccessToken((headers) => this.post(url, headers, responseHandler, errorHandler, data), errorHandler, config)
                } else {
                    errorHandler(error)
                }
            })
    }

    patch = (url, config, responseHandler, errorHandler, data = {}) => {
        axios.patch(process.env.REACT_APP_API_URL + url, data, { headers: { ...config, ...this.defaultHeaders}})
        .then((response) => {
                this.refreshCalls = 0
                responseHandler(response)
        })
        .catch((error) => {
            if (error?.response?.status === 401) {
                this.refreshCalls++
                this.refreshAccessToken((headers) => this.patch(url, headers, responseHandler, errorHandler, data), errorHandler, config)
            } else {
                errorHandler(error)
            }
        })
    }
    
    put = (url, config, responseHandler, errorHandler, data = {}) => {
        axios.put(process.env.REACT_APP_API_URL + url, data, { headers: { ...config, ...this.defaultHeaders}})
        .then((response) => {
                this.refreshCalls = 0
                responseHandler(response)
        })
        .catch((error) => {
            if (error?.response?.status === 401) {
                this.refreshCalls++
                this.refreshAccessToken((headers) => this.put(url, headers, responseHandler, errorHandler, data), errorHandler, config)
            } else {
                errorHandler(error)
            }
        })
    }

    getForeignUrl = (url, config, responseHandler, errorHandler) => {
        axios.get(url, { headers: this.defaultHeaders, ...config })
            .then((response) => {
                    this.refreshCalls = 0
                    responseHandler(response)
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    this.refreshCalls++
                    this.refreshAccessToken((headers) => this.getForeignUrl(url, headers, responseHandler, errorHandler), errorHandler, config)
                } else {
                    errorHandler(error)
                }
            })
    } 
}

export default new ApiService()
import { makeStyles } from "@material-ui/core/styles";

const useNewUserStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  newUserFormContainer: {
      padding: "20px 50px",
      backgroundColor: '#f8f9fb',
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
  },
  newUserInput: {
    width: '100%',
    height: 57,
    borderRadius: 5,
    marginRight: '15px!important',
    backgroundColor: '#fff',
  },
  createNewUserButton: {
    width: '100%',
    maxWidth: 250,
    height: 57,   
  },
  userDataRow: {
    display: "flex"
  },
  newUserHeader: {
    fontSize: "32px!important"
  }
}))

export default useNewUserStyles
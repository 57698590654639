import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    loading: true,
    error: false,
    errorMessage: '',
    usersData: [],
    usersTotal: 0,
}

const name = 'Users'

const UsersSlice = createSlice({
    name,
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setUsersData: (state, action) => {
            state.usersData = action.payload.items
            state.usersTotal = action.payload.count
            state.loading = false
            state.error = false
        },
        setErrorMessage: (state, action) => {
            state.error = true
            state.errorMessage = action.payload
        }
    }
})

export const { setLoading, setUsersData, setErrorMessage } = UsersSlice.actions

export const selectUsers = (state) => state.Users

export default UsersSlice.reducer
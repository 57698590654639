import React from "react";
import { Route, Routes } from "react-router";
import useMainStyles from "./MainPages.styles";
import Navbar from "../../Components/Navbar";
import UsersPage from "../Users/UsersPage";
import NewUserPage from "../NewUser/NewUserPage";
import SettingsPage from "../Settings";

const MainPage = () => {
    const classes = useMainStyles()

    return (
        <div className={classes.root}>
            <Navbar/>
            <main className={classes.pageContainer}>
                <Routes>
                    <Route path="/users" element={<UsersPage/>}/>
                    <Route path="/newuser" element={<NewUserPage/>}/>
                    <Route path="/settings" element={<SettingsPage/>}/>
                </Routes>
            </main>
        </div>
    )
}

export default MainPage
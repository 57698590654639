import { makeStyles } from "@material-ui/core/styles";

const useUsersStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    usersTableWrapper: {
        flexGrow: 1,
    },
    usersTable: {
        backgroundColor: '#fff',
        fontWeight: 300
    },
    usersTableHeader: {
        backgroundColor: '#eef0f4',
        textTransform: 'uppercase',
        fontSize: 12,

    }
}))

export default useUsersStyles
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useNewUserStyles from "./NewUserPage.styles";
import { selectData, selectError, setData, setError } from "./NewUserSlice";

const NewUserPage = () => {
    const dispatch = useDispatch()
    const classes = useNewUserStyles()
    const inputDelayRef = useRef()
    const [isFilled, setIsFilled] = useState(true)
    const [dateTmpValue, setDateTmpValue] = useState()
    const userData = useSelector(selectData)
    const error = useSelector(selectError)

    const handleCreateNewUser = () => {
        axios.post(process.env.REACT_APP_API_URL + "/users/add", 
        userData, 
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const valuesToParse = [
        "annual_income",
        "coverage",
        "zipcode"
    ]


    const handleChangeValues = (e) => {
        if (inputDelayRef.current) {
            clearTimeout(inputDelayRef.current)
            inputDelayRef.current = null
        }
        inputDelayRef.current = setTimeout(() => {
            const validatedInput = validateValues(e.target.value, e.target.id)
            if(validatedInput) {
                dispatch(setData({
                    key: e.target.id,
                    value: validatedInput
                }))
            }
        }, 700)
    }
    
    const handleChangeGender = (e) => {
        dispatch(setData({
            key: "gender",
            value: e.target.value
        }))
    }
    
    const handleChangeProduct = (e) => {
        dispatch(setData({
            key: "selcted_product",
            value: e.target.value
        }))
    }

    const handleChangeStatus = (e) => {
        dispatch(setData({
            key: "status",
            value: e.target.value
        }))
    }

    const handleChangePolicyType = (e) => {
        dispatch(setData({
            key: "policy_type_purchase",
            value: e.target.value
        }))
    }

    const handleChangeDate = (e) => {
        console.log(e);
        setDateTmpValue(e)
    }

    const validateValues = (item, key) => {
        /* Check numeric values */
        if(valuesToParse.includes(key) && isNaN(parseInt(item))) {
            dispatch(setError({
                ...error,
                [key]: "Value should be number!"
            }))
            return false
        }

        /* Check zip code format */
        if(key === "zipcode" && item.length !== 5) {
            dispatch(setError({
                ...error,
                [key]: "Enter valid 5 digit zip code!"
            }))
            return false
        }

        /** Check email format */
        if (key === "email" && !item.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
            dispatch(setError({
                ...error,
                [key]: "Enter valid email!"
            }))
            return false
        }

        dispatch(setError({
            ...error,
            [key]: false
        }))
        if(valuesToParse.includes(key)) {
            return parseInt(item)
        }
        return item

    }

    useEffect(() => {
        const userDataEmptyValues = Object.values(userData).find((element) => {
            if(!element || element === "" || element === 0) {
                return true
            } 
            return false
        })
        if (userDataEmptyValues !== undefined) {
            setIsFilled(true)
        } else {
            setIsFilled(false)
        }
    }, [userData])

    return (
        <div className={classes.newUserFormContainer}>
            <Typography className={classes.newUserHeader}>Create New User</Typography>
            <Grid container spacing={4}>
                <Grid item direction="row" xs={12}>
                    <Typography className={classes.userDataRow}>Personal data</Typography>
                </Grid>
                <Grid item direction="row" xs={12} spacing={1} className={classes.userDataRow}>
                    <TextField id="first_name" variant="outlined" type="text" label="First Name" onChange={handleChangeValues} className={classes.newUserInput}/>
                    <TextField id="last_name" variant="outlined" type="text" label="Last Name" onChange={handleChangeValues} className={classes.newUserInput}/>
                    <FormControl className={classes.newUserInput}>
                        <InputLabel id="gender">Gender</InputLabel>
                        <Select labelId="gender" label="Gender" onChange={handleChangeGender}>
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Not specified">Not specified</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item direction="row" xs={12} className={classes.userDataRow}>
                    <TextField 
                        id="email" 
                        variant="outlined" 
                        type="text" 
                        label="Email" 
                        error={error.email ? true : false}
                        helperText={error.email ? error.email : ""}
                        onChange={handleChangeValues} 
                        className={classes.newUserInput}/>
                    <TextField id="phone_number" variant="outlined" type="text" label="Phone" onChange={handleChangeValues} className={classes.newUserInput}/>
                    <TextField id="city" variant="outlined" type="text" label="City" onChange={handleChangeValues} className={classes.newUserInput}/>
                </Grid>
                <Grid item direction="row" xs={12} className={classes.userDataRow}>
                    <TextField id="street_address_one" variant="outlined" type="text" label="Street Address 1" onChange={handleChangeValues} className={classes.newUserInput}/>
                    <TextField id="street_address_two" variant="outlined" type="text" label="Street Address 2" onChange={handleChangeValues} className={classes.newUserInput}/>
                    <TextField 
                        id="zipcode" 
                        variant="outlined" 
                        type="text" 
                        label="Zip code" 
                        onChange={handleChangeValues} 
                        className={classes.newUserInput}
                        error={error.zipcode ? true : false} 
                        helperText={error.zipcode ? error.zipcode : ""}
                        style={{maxWidth:100}}/>
                </Grid>

                
                <Grid item direction="row" xs={12} className={classes.userDataRow}>
                    <Typography>Product data</Typography>   
                </Grid>
                                
                <Grid item direction="row" xs={12} className={classes.userDataRow}>
                    <FormControl className={classes.newUserInput}>
                        <InputLabel id="product">Product</InputLabel>
                        <Select labelId="product" label="Product" onChange={handleChangeProduct}>
                            <MenuItem value="Term Life">Term Life</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField id="insurance" variant="outlined" type="text" label="Insurance" onChange={handleChangeValues} className={classes.newUserInput}/>
                    <TextField id="affiliate_id" variant="outlined" type="text" label="Affiliate ID" onChange={handleChangeValues} className={classes.newUserInput}/>
                </Grid>              
                <Grid item direction="row" xs={12} className={classes.userDataRow}>
                    <FormControl className={classes.newUserInput}>
                        <InputLabel id="status">Status</InputLabel>
                        <Select labelId="status" label="Status" onChange={handleChangeStatus}>
                            <MenuItem value="active">active</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField 
                        id="coverage" 
                        variant="outlined" 
                        type="text" 
                        label="Coverage" 
                        error={error.coverage ? true : false}
                        helperText={error.coverage ? error.coverage : ""}
                        onChange={handleChangeValues} 
                        className={classes.newUserInput}/>
                    <TextField id="client_ip" variant="outlined" type="text" label="Client IP" onChange={handleChangeValues} className={classes.newUserInput}/>
                </Grid>            
                <Grid item direction="row" xs={12} className={classes.userDataRow}>
                    <FormControl className={classes.newUserInput}>
                        <InputLabel id="policy_type_purchase">Policy Type Purchase</InputLabel>
                        <Select labelId="policy_type_purchase" label="Policy Type Purchase" onChange={handleChangePolicyType}>
                            <MenuItem value="type">type</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField 
                        id="annual_income" 
                        variant="outlined" 
                        type="text" 
                        label="Annual Income"
                        error={error.annual_income ? true : false}
                        helperText={error.annual_income ? error.annual_income : ""} 
                        onChange={handleChangeValues} 
                        className={classes.newUserInput}/>               
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
                        <DateTimePicker
                            label="Date of purchase"
                            mask="__/__/____"
                            displayStaticWrapperAs="desktop"
                            value={dateTmpValue}
                            onChange={handleChangeDate}
                            renderInput={(params) => <TextField {...params} className={classes.newUserInput}/>}/>
                    </LocalizationProvider>
                </Grid>            
                <Grid item container direction="row" xs={12} style={{ minHeight:170 }}>
                    <Grid item xs={8} className={classes.userDataRow}>
                        <TextField id="comment" variant="outlined" type="text" label="comment" onChange={handleChangeValues} className={classes.newUserInput} multiline rows={4}/>
                    </Grid>
                    <Grid item xs={4} className={classes.userDataRow}>

                    </Grid>
                </Grid>
                <Button disabled={isFilled} onClick={handleCreateNewUser} className={classes.createNewUserButton}>
                    Add
                </Button>
            </Grid>
        </div>
    )
}

export default NewUserPage
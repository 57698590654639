import { makeStyles } from "@material-ui/core/styles";

const useSettingsStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    scheduelerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: 15
    },
    scheduelerButton: {
        width: '200px',
        backgroundColor: '#fff',
        borderRadius: 12,
        textTransform: 'capitalize',
        fontSize: 12
    },
    scheduelerButtonContainer: {
        position: 'fixed',
        border: '1px solid #d9e1ee',
        borderRadius: 12,
        right: 80
    }
}))

export default useSettingsStyles;
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import useSettingsStyles from "./SettingsPage.styles";
import SettingsApiService from "./SettingService";

const SettingsPage = () => {

    const classes = useSettingsStyles()
    const ApiService = new SettingsApiService()
    const [scheduelerLink, setScheduelerLink] = useState(null)
    const [scheduelerStatus, setScheduelerStatus] = useState(true)

    useEffect(() => {
        ApiService._getScheduelerLink((response) => {
            setScheduelerLink(response.data.authentication_url)
            setScheduelerStatus(true)
        },(error) => {
            console.log(error)
            setScheduelerStatus(false)
        })
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.scheduelerContainer}>
            {
               (scheduelerLink && scheduelerStatus) && <div className={classes.scheduelerButton}>
                   <Button variant="outlined" className={classes.scheduelerButton} href={scheduelerLink}>Scheduler</Button>
                </div>
            }
            </div>
        </div>
    )
}

export default SettingsPage;
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    loading: true,
    userData: {
        "affiliate_id": "",
        "annual_income": 0,
        "city": "",
        "client_ip": "",
        "comment": "",
        "coverage": 0,
        "date_of_purchase": "Mon, 18 Oct 2021 14:09:54 GMT",
        "email": "",
        "first_name": "",
        "form_name": 1,
        "gender": "",
        "insurance": "",
        "last_name": "",
        "phone_number": "",
        "policy_type_purchase": "",
        "second_phone": "0aaa",
        "selcted_product": "",
        "status": "",
        "street_address_one": "",
        "street_address_two": "",
        "zipcode": ""
    },
    error: {}
}

const name = 'NewUser'

export const NewUserSlice = createSlice({
    name,
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setData: (state, action) => {
            state.userData = { ...state.userData, [action.payload.key]: action.payload.value}
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setLoading, setData, setError } = NewUserSlice.actions

export const selectData = (state) => state.NewUser.userData
export const selectError = (state) => state.NewUser.error

export default NewUserSlice.reducer
import ApiService from "../../global/service";

export default class UsersApiService {

    _getUsers = (requestHandler, errorHandler) => ApiService.get("/users", {
        params: {
            'key': 'YTSBx3Xv3J0QcB91kKT4seLwA4O03gydBejNzfRKEXiOYBRO_00dtFJ5TU2aVvxn',
            'sort': '-users.user_id'
        }
    }, requestHandler, errorHandler)
}
import { makeStyles } from "@material-ui/core/styles";

const useMainStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  pageContainer: {
      width: '100%',
      minHeight: '100vh',
      padding: 20,
      paddingTop: 95,
      backgroundColor: '#f8f9fb',
  }
}))

export default useMainStyles
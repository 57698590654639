import { TextField, Button, Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useLoginStyles from "./LoginPage.styles";
import { selectLogin, setError, setLoading } from "./LoginSlice";

const LoginPage = () => {
    const classes = useLoginStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loading, error } = useSelector(selectLogin)
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    })
    const [hasValues, setHasValues] = useState(false)

    const handleChangeEmail = (value) => setLoginData({ ...loginData, email: value})
    const handleChangePassword = (value) => setLoginData({ ...loginData, password: value})

    const handleLogin = () => {
        axios.post(process.env.REACT_APP_AUTH_API_URL + "/v1/auth/login", loginData).then((response)=>{
            localStorage.setItem("access_token",response.data["access_token"])
            localStorage.setItem("refresh",response.data["refresh_token"])
            navigate("/")
        }).catch((err) => {
            console.log(err);
            dispatch(setError({status: true}))
        })
    }

    useEffect(() => {
        dispatch(setLoading(false))
    })

    useEffect(() => {
        if(loginData.email && loginData.password) {
            setHasValues(true)
        } else {
            setHasValues(false)
        }
    }, [loginData])

    return !loading ? (
        <div className={classes.loginWrapper}>
            <form
                component="form"
                autoComplete="off"
                className={classes.loginForm}
                onSubmit={handleLogin}
                >
                    <div className={classes.inputContainer}>
                        <TextField 
                            id="email"
                            label="Email"
                            variant="filled"
                            className={classes.loginInput}
                            onChange={(e) => handleChangeEmail(e.target.value)}
                            error={error}
                            />
                    </div>
                    <div className={classes.inputContainer}>
                        <TextField 
                            id="password"
                            label="Password"
                            type="password"
                            variant="filled"
                            className={classes.loginInput}
                            onChange={(e) => handleChangePassword(e.target.value)}
                            error={error}/>
                    </div>
                    <Button 
                        variant="contain"
                        className={hasValues ? classes.submitButton : classes.submitButtonDisabled} 
                        disabled={!hasValues}
                        onClick={() => handleLogin()}>
                        Log in
                    </Button>
            </form>
        </div>
    ) : (
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open>
            <CircularProgress color="inherit"/>
        </Backdrop>
    )
}

export default LoginPage;
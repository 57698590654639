import { makeStyles } from "@material-ui/core/styles";

const useLoginStyles = makeStyles(() => ({
    loginWrapper: {
        display:'flex',
        justifyContent: 'center',
    },
    loginForm: {
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 20%',
        borderRadius: 5,
        border: '1px solid #9bb6c97d',
        padding: 20,
        backgroundColor: '#fff',
        marginTop: 250
    },
    loginInput: {
        width: '100%',
        height: 50,
        borderRadius: 5,
    },
    inputContainer: {
        marginBottom: 20
    },
    submitButton: {
        width: '100%',
        height: 50,
        borderRadius: 5,
        backgroundColor: '#363ed3!important',
        color: '#fff!important',
        cursor: 'pointer'

    },
    submitButtonDisabled: {
        width: '100%',
        height: 50,
        borderRadius: 5,
        border: '1px solid!important',
        opacity: .7,

    }
}))

export default useLoginStyles
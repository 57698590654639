import { makeStyles } from "@material-ui/core/styles";

const useNavStyles = makeStyles((theme) => ({
    navBar: {
        [theme.breakpoints.up('sm')]: {
          width: 230,
          flexShrink: 0,
        },
    },
    navItem: {
        fontSize: 18,
        textTransform: 'uppercase',
        fontWeight: 500,
        color: '#fff!important',
        fill: '#fff',
    },
    navItemActive: {
        backgroundColor: '#fff!important',
        border: 'none',
        color: '#363ed3!important',
        fill: '#363ed3',
    },
    navItemContainer: {
        display: 'flex',
        width:'100%',
        justifyContent: 'space-between',
        padding: '10px 0',
        border: "1px solid #d9d9d9",
        borderRight: 'none',
        borderLeft: 'none'
    },
    navLogoContainer: {
      marginBottom: 15,
    },
    toolBar: {        
        paddingLeft: 40
    },
    drawerPaper: {
      width: 230,
      backgroundColor: '#363ed3!important',
      color: '#fff',
      fontSize: 9,
      padding: '45px 0'
    },
    listItemIcon: {
      justifyContent: 'center'
    },
    logoutContainer: {
      position: 'relative',
      top: '70vh',
      fill: '#fff',
      cursor: 'pointer',
      marginTop: 10
    },
    settingsContainer: {
      position: 'relative',
      top: '70vh',
      fill: '#fff',
      cursor: 'pointer'
    }
}))

export default useNavStyles
import { useTheme } from "@emotion/react";
import { Button, Dialog, DialogActions, DialogTitle, Drawer, Hidden } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ReactComponent as Users } from '../../Assets/inline-svg/icon-users.svg'

import useNavStyles from "./Navbar.styles";
import NavbarItem from "./NavbarItem/NavbarItem";
import { ReactComponent as LogoIcon } from '../../Assets/inline-svg/icon-logo.svg'

const Navbar = (props) => {
    const { window } = props;
    const classes = useNavStyles()
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()
    const [activePage, setActivePage] = useState('')
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false)

    const handleOpenLogoutDialog = () => {
        setOpenLogoutDialog(true)
    }

    const handleCloseLogoutDialog = () => {
        setOpenLogoutDialog(false)
    }

    const handleLogout = () => {
        handleCloseLogoutDialog()
        localStorage.removeItem("access_token")
        navigate("/login")
    }

    const handleOpenSettings = () => {
        navigate("/settings")
    }

    const menuOptions = [
        {
            name: 'Users',
            Icon: <Users/>,
            link: '/users'
        }
    ]
    
    const container = window !== undefined ? () => window().document.body : undefined;

    useEffect(() => {
        setActivePage(location.pathname)
    }, [location])

    return (
    <nav className={classes.navBar}>
        <Hidden smUp implementation="css">
            <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                classes={{
                paper: classes.drawerPaper,
                }}
                ModalProps={{
                keepMounted: true,
                }}>            
                    <div className={classes.navLogoContainer}>
                        <LogoIcon/>
                    </div>
                    {
                        menuOptions.map(({ name, link }) => {
                            return (
                                <NavbarItem name={name} link={link} key={name} activeOption={activePage}/>
                            )
                        })
                    }
            </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
            <Drawer
                classes={{
                paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
            >
                <div className={classes.navLogoContainer}>
                    <LogoIcon/>
                </div>
                {
                    menuOptions.map(({ name, link }) => {
                        return (
                                <NavbarItem name={name} link={link} key={name} activeOption={activePage}/>
                        )
                    })
                }
                <div className={classes.settingsContainer} onClick={handleOpenSettings}>
                    <svg width="48" height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 8.666c-1.838 0-3.333 1.496-3.333 3.334s1.495 3.333 3.333 3.333 3.333-1.495 3.333-3.333-1.495-3.334-3.333-3.334m0 7.667c-2.39 0-4.333-1.943-4.333-4.333s1.943-4.334 4.333-4.334 4.333 1.944 4.333 4.334c0 2.39-1.943 4.333-4.333 4.333m-1.193 6.667h2.386c.379-1.104.668-2.451 2.107-3.05 1.496-.617 2.666.196 3.635.672l1.686-1.688c-.508-1.047-1.266-2.199-.669-3.641.567-1.369 1.739-1.663 3.048-2.099v-2.388c-1.235-.421-2.471-.708-3.047-2.098-.572-1.38.057-2.395.669-3.643l-1.687-1.686c-1.117.547-2.221 1.257-3.642.668-1.374-.571-1.656-1.734-2.1-3.047h-2.386c-.424 1.231-.704 2.468-2.099 3.046-.365.153-.718.226-1.077.226-.843 0-1.539-.392-2.566-.893l-1.687 1.686c.574 1.175 1.251 2.237.669 3.643-.571 1.375-1.734 1.654-3.047 2.098v2.388c1.226.418 2.468.705 3.047 2.098.581 1.403-.075 2.432-.669 3.643l1.687 1.687c1.45-.725 2.355-1.204 3.642-.669 1.378.572 1.655 1.738 2.1 3.047m3.094 1h-3.803c-.681-1.918-.785-2.713-1.773-3.123-1.005-.419-1.731.132-3.466.952l-2.689-2.689c.873-1.837 1.367-2.465.953-3.465-.412-.991-1.192-1.087-3.123-1.773v-3.804c1.906-.678 2.712-.782 3.123-1.773.411-.991-.071-1.613-.953-3.466l2.689-2.688c1.741.828 2.466 1.365 3.465.953.992-.412 1.082-1.185 1.775-3.124h3.802c.682 1.918.788 2.714 1.774 3.123 1.001.416 1.709-.119 3.467-.952l2.687 2.688c-.878 1.847-1.361 2.477-.952 3.465.411.992 1.192 1.087 3.123 1.774v3.805c-1.906.677-2.713.782-3.124 1.773-.403.975.044 1.561.954 3.464l-2.688 2.689c-1.728-.82-2.467-1.37-3.456-.955-.988.41-1.08 1.146-1.785 3.126"/></svg>
                </div>
                <div className={classes.logoutContainer} onClick={handleOpenLogoutDialog}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M14 12h-4v-12h4v12zm4.213-10.246l-1.213 1.599c2.984 1.732 5 4.955 5 8.647 0 5.514-4.486 10-10 10s-10-4.486-10-10c0-3.692 2.016-6.915 5-8.647l-1.213-1.599c-3.465 2.103-5.787 5.897-5.787 10.246 0 6.627 5.373 12 12 12s12-5.373 12-12c0-4.349-2.322-8.143-5.787-10.246z"/></svg>
                </div>
            </Drawer>
            
        </Hidden>
        <Dialog
        open={openLogoutDialog}
        onClose={handleCloseLogoutDialog}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description">
            <DialogTitle id="logout-dialog-title">
                Are you sure you want to log out?
            </DialogTitle>
            <DialogActions>
                <Button variant="outlined" onClick={handleCloseLogoutDialog}>
                    No
                </Button>
                <Button variant="outlined" onClick={handleLogout} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    </nav>
    )
}

export default Navbar
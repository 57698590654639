import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router";
import "./App.css"
import LoginPage from "./Pages/Login";
import MainPage from "./Pages/Main/MainPage";

const App = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if(!localStorage.getItem('access_token')) {
      navigate("/login")
    }
  }, [navigate])

  return (
    <div className="App">
          <Routes>
            <Route exact path="/login" element={<LoginPage/>}/>
            <Route path="*" element={<MainPage/>}/>
          </Routes>
    </div>
  );
}

export default App;

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    loading: true,
    error: false
}

const name = 'Login'

export const LoginSlice = createSlice({
    name,
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        }
    }
})

export const { setLoading, setError } = LoginSlice.actions

export const selectLogin = (state) => state.Login

export default LoginSlice.reducer
import { configureStore } from "@reduxjs/toolkit";
import LoginReducer from "../Pages/Login/LoginSlice";
import UsersReducer from "../Pages/Users/UsersSlice";
import NewUserReducer from "../Pages/NewUser/NewUserSlice"

export default configureStore({
    reducer: {
        Login: LoginReducer,
        Users: UsersReducer,
        NewUser: NewUserReducer
    }
})
import { Backdrop, CircularProgress } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useUsersStyles from "./UsersPage.styles";
import UsersApiService from "./UsersService";
import { selectUsers, setErrorMessage, setUsersData } from "./UsersSlice";

const UsersPage = () => {
    const classes = useUsersStyles();
    const dispatch = useDispatch();
    const ApiService = new UsersApiService()

    const users = useSelector(selectUsers)
    const { loading, usersData } = users

    const columns = [
        { field: 'user_id', headerName: 'ID', width: 40, headerClassName: classes.usersTableHeader},
        { field: 'first_name', headerName: 'First Name', headerClassName: classes.usersTableHeader},
        { field: 'last_name', headerName: 'Last Name', headerClassName: classes.usersTableHeader},
        { field: 'email', headerName: 'E-mail', width: 180, headerClassName: classes.usersTableHeader },
        { field: 'phone', headerName: 'Phone', width: 120, headerClassName: classes.usersTableHeader },
        { field: 'created_at', headerName: 'Create at', width: 220, headerClassName: classes.usersTableHeader },
        { field: 'state', headerName: 'State', width: 70, headerClassName: classes.usersTableHeader },
        { field: 'zipcode', headerName: 'Zip', headerClassName: classes.usersTableHeader},
        { field: 'affiliate_id', headerName: 'Affiliate ID', headerClassName: classes.usersTableHeader },
        { field: 'coverage', headerName: 'Coverage', headerClassName: classes.usersTableHeader},
        { field: 'annual_income', headerName: 'Income', headerClassName: classes.usersTableHeader},
        { field: 'insurance', headerName: 'Insurance', headerClassName: classes.usersTableHeader},
        { field: 'selected_product', headerName: 'Product', headerClassName: classes.usersTableHeader},
        { field: 'form_name', headerName: 'Form name', headerClassName: classes.usersTableHeader},
        { field: 'status', headerName: 'Status', headerClassName: classes.usersTableHeader},
        { field: 'ts_id', headerName: 'ts ID', headerClassName: classes.usersTableHeader},

    ]

    useEffect(() => {
        ApiService._getUsers(
            (response) => dispatch(setUsersData(response.data)),
            (error) => {
                dispatch(setErrorMessage("Error loading users data"))
                console.error(error);
            })
    }, [])

    return !loading ? (
        <div className={classes.root}>
            <div className={classes.usersTableWrapper}>
                <DataGrid
                        rows={usersData}
                        columns={columns}
                        getRowId={(row) => row.user_id}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        //checkboxSelection
                        autoHeight
                        className={classes.usersTable}
                        />

            </div>
        </div>
    ) : (
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open>
            <CircularProgress color="inherit"/>
        </Backdrop>
    )
}

export default UsersPage